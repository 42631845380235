// Limitação de caracteres
function limitCaracters(el, nMaxCaracters) {
    el.each(function() {
        var elText =  $(this).text();
        var minText = elText.substring(0, nMaxCaracters);
        var nCaractersTotal = elText.length;
        if (nCaractersTotal > nMaxCaracters) {
            $(this).text(minText.trim() + '...');
        } else {
            $(this).text(elText);
        }
    });
}
// Exemplo:
// limitCaracters($('.elementoTESTE li div'), 50);


// substituir palavras quando for dispositivo for de toque
// necessita do plugin is.js para funcionar
function changeTouchClickText() {
    if (is.touchDevice()) {
        $('.changeString').each(function() {
            var str = $(this).text();
            console.log(str);
            if (str === 'clique') {
                $(this).html('toque');
            } else if (str === 'Clique') {
                $(this).html('Toque');
            }
        });
    } else {
        $('.changeString').each(function() {
            var str = $(this).text();
            console.log(str);
            if (str === 'toque') {
                $(this).html('clique');
            } else if (str === 'Toque') {
                $(this).html('Clique');
            }
        });
    }
}
// Exemplo:
// $(window).on('load', changeTouchClickText());


// classe diferente no body para cada página
function classOnBody(el) {
    var mainId = el;
    var prefixClass = 'bodyClass__';
    $('body').addClass(prefixClass + mainId);
}
// Exemplo:
// $(window).on('load', classOnBody($('main').attr('id')));


// identifica se é IE e exibe mensagem
// necessita do plugin is.js para funcionar
function showMessageOnIE() {
    var componentEl = $('#w_ieDetect');
    var componentEl__mask = $('#w_ieDetect__mask');
    if (is.ie()) {
        $('body').css('overflow', 'hidden');
        componentEl.removeClass('u-dnone').addClass('is-visible');
        componentEl__mask.removeClass('u-dnone');
        componentEl__mask.on('click', function() {
            componentEl__mask.remove();
            componentEl.removeClass('is-visible');
            componentEl.addClass('is-fixed');
            $('body').css('overflow', 'auto');
        });
    } else {
        componentEl.remove();
        componentEl__mask.remove();
    }
}
// Exemplo:
// $(window).on('load', showMessageOnIE());


// link scroll
function goToSection__scroll(elRef, distance) {
    var el = $(elRef);
    var goScroll = $(el).offset().top;
    setTimeout(function(){
        $('html, body').animate({
            scrollTop: goScroll - distance
        }, 700);
    }, 10);
}
// Exemplo:
//$('.linkScroll').on('click', function() {goToSection__scroll($('.linkScroll'), 50)})